import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Página no encontrada" />
    <div className="container-demo">
      <h1>:( La página que buscabas ya no existe</h1>
      <Link to="/">Regresar a Inicio</Link>
    </div>
  </Layout>
)

export default NotFoundPage
